import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { PageProps, graphql, Link } from "gatsby"
import News from "~/components/News"
import useScrollToTop from "~/hooks/useScrollToTop"
import useMaxWidth from "~/hooks/useMaxWidth"
import Tiles from "~/components/Grid/Tiles"
import LazyLoad from "react-lazyload"
import Animated from "~/components/Animated"
import FilterNav from "~/components/FilterNav"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { I18nContext } from "~/components/I18n"

export const query = graphql`{
  site {
    siteMetadata {
      title
      description
      author
      image
      siteUrl
    }
  }
  wpMenuItem(url: {regex: "/^\\/news/"}) {
    id
    label
  }
  allWpCategory(sort: {fields: name}, filter: {count: {gte: 1}}) {
    edges {
      node {
        id
        name
        uri
        count
        language {
          slug
        }
      }
    }
  }
  allWpPost(
    sort: {
      order: DESC,
      fields: date
    }
    filter: {
      nodeType: {
        eq: "Post"
      },
      status: {
        in: ["publish", "future"]
      }
    }
  ) {
    edges {
      node {
        id
        uri
        date
        title
        excerpt
        language {
          slug
        }
        featuredImage {
          node {
            sourceUrl
            srcSet
            sizes
            mimeType
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1200, layout: CONSTRAINED, quality: 92)
              }
            }
          }
        }
      }
    }
    distinct(field: id)
  }
}
`

export default ({
  data: {
    allWpPost,
    allWpCategory,
    wpMenuItem: menuItem,
    site: {
      siteMetadata: {
        title,
        description,
        image,
        author,
        siteUrl
      },
    },
  },
}: PageProps<{
  site: {
    siteMetadata: {
      title: string
      description: string
      image: string
      author: string
      siteUrl: string
      apiUrl: string
    }
  }
  wpMenuItem?: {
    id: string
    label: string
  },
  allWpCategory: {
    edges: {
      node: {
        id: string
        name: string
        uri: string
        count: number
        language: {
          slug: string
        }
      }
    }[]
  }
  allWpPost: {
    edges: {
      node: {
        id: string
        uri: string
        date: string
        title: string
        excerpt: string
        language: {
          slug: string
        }
        featuredImage: {
          node: {
            sourceUrl: string
            srcSet: string
            sizes: string
            mimeType: string
            localFile: {
              childImageSharp: {
                gatsbyImageData: IGatsbyImageData
              }
            }
          }
        }
      }
    }[]
  }
}>) => {
  useScrollToTop()
  useMaxWidth()

  const { locale } = useContext(I18nContext)

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{
          lang: locale,
          dir: "ltr",
          prefix: "og: http://ogp.me/ns#",
        }}
      >
        <title>{title}</title>
        <link rel="author" href="/humans.txt" />

        <meta name="description" content={description} />
        <meta name="image" content={`${siteUrl}${image}`} />

        <meta property="og:title" content={title} />
        <meta property="og:locale" content={locale} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${siteUrl}${image}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={author} />
        <meta name="twitter:creator" content={author} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${siteUrl}${image}`} />

      </Helmet>


      <header>
        <h1>{menuItem?.label || "News"}</h1>

        <FilterNav>
          {React.Children.toArray(allWpCategory.edges
          .filter((c) => {
            return c.node.language === null || locale === c.node.language.slug
          })
          .map(({
            node: {
              id, uri, name
            }
          }) => {
            return (
              <Link
                id={id}
                to={uri}
                key={id}
                title={name}
              >{name}</Link>
            )
          }))}
        </FilterNav>
      </header>

      <section>
        <Tiles className="has-margin-top news-grid">
          {React.Children.toArray(allWpPost.edges
            .filter(({ node: { date, language } }) =>
              new Date(date) <= new Date() && (
                language === null || locale === language.slug
              ))
            .map(({
              node: {
                id, title, uri, date, excerpt, featuredImage
              }
            }, i) => {
              return (
                <LazyLoad once key={id}>
                  <Animated
                    duration={`500ms`}
                    delay={`${150 * i}ms`}
                  >
                    <News
                      id={id}
                      uri={uri}
                      title={title}
                      subtitle={excerpt}
                      date={new Date(date)}
                      display={true}
                      featuredImage={featuredImage}
                    />
                  </Animated>
                </LazyLoad>
              )
            }))}
        </Tiles>
      </section>
    </>
  )
}
